import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/coremedia-ci/documentation-site/documentation-site/src/components/ArticleLayout/index.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Breadcrumbs`}</h2>
    <ul>
      <li parentName="ul"><span parentName="li" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "0",
            "marginRight": "0",
            "maxWidth": "67px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/30c5a123c5bfd4cd675713661d506403/55ef4/ec-adCenter.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "104.4776119402985%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAVCAYAAABG1c6oAAAACXBIWXMAABYlAAAWJQFJUiTwAAACc0lEQVQ4y41V2XaiQBDNB8w5zmgii4iC24hZVOK+4BKDsiXRZP7/T+6cKoGgiZqHC0139a2qvl3FlZjTcAqCXDwzV/x2z9WpjUmCG1FlCPJl51fnyCKUqwZKVQM/ySYmvBbyyGTz/E5ClIuwZgsGpXm8nskqHH1EyilnpQLuH0wsFitYsyWDxt3eCP3hBIPhBG2zh9HYQqc7xDxhN188oVa/Zw6OkNhLFQMvr2+8qdXuMtpml50MR1OsVjam1gLjiYVmq4OW2WXQuNcfwQ9eIef1fYQUtnHbxOrZRiotxmkQorS00l8U9Cr+XMvxfEb4tHFdn9cpSiasNx7wbK9xI+YhKXp80Dm1BFnR+YzUYhXGbQt6uR6nR6B11/OhapVPQiMkvBaU2JDmN47HKf/OSNg4Lm/0/YDTjISQctpXwnqCUAojonPdbndwXA+iXGDyUqWOXF5H8PLK62QnKz8gTGdzrJ7jeHjb7nD3YGL59IxytY5fqSzstQPjrsnX7CIhKVXQa9judphac9j2huF6AczHHvqDMTw/iFWVLhHSZKXWQPuxx6oKUgGd7gDDsYX1xsVsvkRRr8XCXDjDvcrkmRZpTKAxQ1TZZh+ZHqqs8bU5UrnJaZGaB2UlKBz1cbkdl53nBSho4T2kB334wQunM5nOMJnOw/cpzBlUOU8rG+u1w3V+UMt0JaicqMR2u3e02h2YnT7Mx0PQ2dKZfnz8Y4GoTNUwuoNuQxOpjIRytcFeo/TpahyChFM5KlI6fZOLW1ccYbInyvkSezzX++iqkE2yTON+uM9dO/AShX/cfI9tkt/7bh5G+N0/5GsnL551Eq3/B45MXNHI/hT6AAAAAElFTkSuQmCC')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/30c5a123c5bfd4cd675713661d506403/381f8/ec-adCenter.webp 67w"],
                "sizes": "(max-width: 67px) 100vw, 67px",
                "type": "image/webp"
              }}></source>{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/30c5a123c5bfd4cd675713661d506403/55ef4/ec-adCenter.png 67w"],
                "sizes": "(max-width: 67px) 100vw, 67px",
                "type": "image/png"
              }}></source>{`
          `}<img parentName="picture" {...{
                "className": "gatsby-resp-image-image",
                "src": "/static/30c5a123c5bfd4cd675713661d506403/55ef4/ec-adCenter.png",
                "alt": "icon-adCenter",
                "title": "icon-adCenter",
                "loading": "lazy",
                "decoding": "async",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                }
              }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span>{` > Apps > Add `}</li>
    </ul>
    <h2>{`Engagement Studio Settings`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Field`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`Name`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Required`}</strong>{` - It is free text but you should give a meaningful name.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`Active`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`Yes`}</em>{` or `}<em parentName="td">{`No`}</em>{`.`}</td>
        </tr>
      </tbody>
    </table>
    <h2>{`Contact Center & Analytics Studio Settings`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Field`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`Assisted navigation`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Required`}</strong>{` - Must be changed to `}<em parentName="td">{`Yes`}</em></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`Privacy url`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Provide the URL.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`Navigation mode`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Required`}</strong>{` - Decide who has the control. Select from `}<em parentName="td">{`controled by the visitor`}</em>{`, `}<em parentName="td">{`controled by the operator`}</em>{` or `}<em parentName="td">{`shared control`}</em></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`Close window automatically`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Required`}</strong>{` `}<em parentName="td">{`Yes`}</em>{` or `}<em parentName="td">{`No`}</em>{`. If you want the window to be automatically closed (`}<em parentName="td">{`Yes`}</em>{`) or not.`}</td>
        </tr>
      </tbody>
    </table>
    <blockquote>
      <p parentName="blockquote">{`The file size restriction is 30MB. At the end of the upload, a scan is being carried out to validate the file against an anti-virus.`}</p>
    </blockquote>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      